let getNotificationObject = (type, title, description, permanent, timeout, showEmoji) => {
  return {
    id: Math.random(),
    type: type,
    permanent: permanent,
    title: title,
    description: description,
    timeout: timeout,
    showEmoji: showEmoji,
  }
}

const  htmlEncode = (input) => {
  if(input !== undefined) {
    return input
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  }
}


export default {
  namespaced: true,
  state: {
    notificationList: [],
    staticNotification: null,
  },
  getters: {
    notificationList: state => state.notificationList,
    staticNotification: state => state.staticNotification,
  },
  mutations: {
    removeMessage(state, index) {
      state.notificationList.splice(index, 1)
    },
    clearStaticMessage(state) {
      state.staticNotification = null
    },
    successNotification(state, { title, description, permanent, timeout }) {
      let notification = getNotificationObject('success', title, description, permanent, timeout)
      state.notificationList.unshift(notification)
    },
    successStaticNotification(state, { title, description, permanent, timeout }) {
      let notification = getNotificationObject('success', title, description, permanent, timeout)
      state.staticNotification = notification
    },
    errorNotification(state, { title, description, permanent, timeout }) {
      let notification = getNotificationObject('danger', title, htmlEncode(description), permanent, timeout)
      state.notificationList.unshift(notification)
    },
    errorStaticNotification(state, { title, description, permanent, timeout, showEmoji }) {
      let notification = getNotificationObject(
        'danger',
        title,
        htmlEncode(description),
        permanent,
        timeout,
        showEmoji
      )
      state.staticNotification = notification
    },
    setStaticNotification(state, { title, description, permanent, type, timeout, showEmoji }) {
      let notification = getNotificationObject(
        type,
        title,
        description,
        permanent,
        timeout,
        showEmoji
      )
      state.staticNotification = notification
    },
    warningNotification(state, { title, description, permanent, timeout }) {
      let notification = getNotificationObject('warning', title, description, permanent, timeout)
      state.notificationList.unshift(notification)
    },
    primaryNotification(state, { title, description, permanent, timeout }) {
      let notification = getNotificationObject('primary', title, description, permanent, timeout)
      state.notificationList.unshift(notification)
    },
    infoNotification(state, { title, description, permanent, timeout }) {
      let notification = getNotificationObject('info', title, description, permanent, timeout)
      state.notificationList.unshift(notification)
    },
  },
}
